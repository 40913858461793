@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700&display=swap');

/* Global Styles!!! */
:root {
  --engine-card-box-shadow: 0px 16px 32px -16px rgba(54, 84, 99, 0.3),
    0px 0px 2px 0px rgba(54, 84, 99, 0.03);
  --engine-card-box-shadow-hover: 0px 16px 32px -16px rgba(54, 84, 99, 0.5),
    0px 0px 2px 0px rgba(54, 84, 99, 0.03);
  --engine-card-box-shadow-hover-transition: box-shadow 250ms;
  --engine-card-radius: 10px;
  --engine-card-margin: 0 16px 20px;
  --engine-container-with-fab-padding: 16px 0 92px;
  --engine-page-background: #f9fafa;
  --engine-easing-function-expo: cubic-bezier(0.22, 1, 0.36, 1);
  --engine-active-element-box-shadow: 0px 12px 24px -6px rgba(54, 84, 99, 0.2),
    0px 0px 2px 0px rgba(54, 84, 99, 0.03);
  --engine-placeholder-color: #8f9ca3;
}

* {
  outline: none;
}

body {
  background: #fff;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  overflow-x: hidden;
  color: #67757c;
}

html {
  position: relative;
  height: calc(
    100% + var(--ion-safe-area-bottom) + var(--ion-safe-area-top)
  ) !important;
  height: calc(
    100% + var(--ion-safe-area-bottom) + var(--ion-safe-area-top)
  ) !important;
  background: #fff;
}

a {
  text-decoration: none !important;
}

body,
html {
  scroll-behavior: smooth;
  font-family: 'Source Sans Pro', sans-serif;
}

/* Needed for displaying dropdown for modal */
.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 99999 !important;
}

cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;
}

.app-w100 {
  width: 100%;
}
