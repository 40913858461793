/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

[hidden],
[data-hidden='true'] {
  display: none !important;
}

input:focus,
textarea:focus,
div[contenteditable]:focus {
  outline: none;
}

body > div {
  display: none;
}

ion-list-header {
  & > ion-label {
    margin-top: 16px;
    color: var(--ion-color-step-900);
  }
}

ion-item-option {
  font-weight: 400;
}

ion-toast {
  --background: #222428;
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.25);
  --color: #fff;
  --max-width: 420px;
  font-size: 16px;
  font-weight: 500;

  &::part(message) {
    margin: 0 4px;
  }

  &::part(button) {
    margin: 0 4px;
  }
}

.popover-datetime {
  &::part(content) {
    width: 350px !important;
    max-width: 95vw;
  }
}

.app-toolbar {
  --color: var(--ion-text-color);
}

.app-notice {
  color: var(--ion-color-step-650);
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;

  strong {
    font-weight: 600;
  }
}

.input-with-background {
  --background: var(--engine-page-background);
  --padding-end: 16px;
  --padding-start: 16px;
  --border-radius: 10px;
  --color: var(--ion-text-color);
  font-weight: 400;
}

.ion-page-background {
  background: var(--engine-page-background);
}

ion-button {
  --border-radius: 10px;
}

input {
  caret-color: var(--ion-color-primary) !important;
}

.select-round-fix {
  border-radius: var(--border-radius, 0);
  overflow: hidden;
}

.semi-bold {
  font-weight: 600;
}

.app-divider-line {
  border-bottom: 1px solid var(--ion-color-step-250);
}

.app-common-background {
  --background: var(--ion-background-color);
}

.fix-textarea {
  .native-wrapper {
    overflow: auto;
  }
}

.reorder-selected.opaque-reorder-item {
  opacity: 1 !important;
}

.modal-auto-height {
  --height: auto;
  --box-shadow: var(--engine-card-box-shadow);
  --backdrop-opacity: 0.4 !important;

  .ion-page {
    position: relative;
    display: block;
    contain: content;
    height: auto;

    & > app-full-height-container {
      max-height: 80vh;
      overflow: auto;
      min-height: 0;
      justify-content: initial;
    }
  }
}

.container-with-fab {
  padding: var(--engine-container-with-fab-padding);
}

.main-fab {
  padding: 0 8px calc(var(--ion-safe-area-bottom) + 8px);
}

.wide-alert-button {
  min-height: 64px;
}

.wide-alert {
  --max-width: 340px;
}

ion-content {
  --keyboard-offset: 0 !important;
}

ion-toolbar {
  &.app-toolbar {
    --background: var(--engine-page-background);
  }
}

ion-segment-button {
  --indicator-color: #fff;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

ion-action-sheet {
  .action-sheet-container {
    .action-sheet-title.sc-ion-action-sheet-ios,
    .action-sheet-title.sc-ion-action-sheet-md {
      font-size: 16px;
      font-weight: 400;
      color: var(--ion-color-step-900);
      padding: 12px 24px;
    }
  }
}

.fullscreen-modal {
  --background: transparent;
  --width: 100%;
  --height: 100%;
  --border-radius: 0;

  ion-header {
    margin-top: var(--ion-safe-area-top);
  }
}

ion-reorder-group {
  ion-reorder {
    ion-icon.reorder-handle-icon {
      width: 24px;
      height: 24px;
    }
  }

  &.reorder-group-with-wrappers {
    &[ng-reflect-disabled='false'] {
      ion-reorder {
        pointer-events: none;

        ion-icon.reorder-handle-icon {
          pointer-events: all;
        }
      }
    }
  }
}

ion-modal {
  --max-width: 440px;
  --border-radius: 10px;
}

.modal-body {
  background: var(--engine-page-background);

  ion-list {
    ion-item {
      font-weight: 400;
    }

    &:not(.list-inset) {
      ion-item {
        --background: var(--engine-page-background);
      }
    }
  }
}

.modal-actions {
  padding-left: 16px;
  padding-right: 16px;
  display: flex !important;
  align-items: center;
  max-width: 440px;
  margin: 0 auto;
  background: var(--engine-page-background);
  flex-wrap: wrap;
  gap: 8px;

  & > * {
    flex: 1;
  }
}

.highlighted-item {
  --background: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.07) !important;
}

app-slim-menu-button {
  padding: 0 2px;

  ion-menu-button {
    &::part(native) {
      width: 38px;
      height: 32px;
    }
  }
}

.hidden-control {
  position: absolute;
  left: -10000px;
  top: -10000px;
  visibility: hidden;
}
